<template>
  <b-card>
    <div class="d-flex justify-content-between">
      <div>
        <b-card-title>Total Incomes</b-card-title>
        <b-card-sub-title>
          <span>Total incomes in 24 months</span>
        </b-card-sub-title>
        <b-form-checkbox
            v-model="isIncomeShowAll"
            plain
        >
          Show all
        </b-form-checkbox>
      </div>
      <div>
        <div class="mb-1">
          Current month:
        </div>
        <div>
          <skeleton-loader :is-loading="isDataLoading">
            <strong class="text-success font-large-2">
              {{ currencyFormat(totalIncome[totalIncome.length - 1]['total_income']) }}
            </strong>
          </skeleton-loader>
        </div>
      </div>
    </div>
    <b-card-text>
      <statistic-total-income :data="totalIncome"/>
    </b-card-text>
  </b-card>
</template>

<script>
import inputHelper from "@/mixins/utils/inputHelper";
import dataRequester from "@/mixins/utils/dataRequester";
import invoiceService from "@/mixins/utils/axios/invoice.service";

import StatisticTotalIncome from "@/views/pages/dashboard/chart/StatisticTotalIncome.vue";
import SkeletonLoader from "@/views/components/loader/SkeletonLoader.vue";

export default {
  name: 'Dashboard',
  mixins: [inputHelper, dataRequester],
  components: {
    StatisticTotalIncome,
    SkeletonLoader
  },
  data() {
    return {
      totalIncome: [{}],
      isDataLoading: false,
      isIncomeShowAll: false,
    }
  },
  computed: {
  },
  watch: {
    isIncomeShowAll: {
      handler: function (value) {
        if (value) {
          this.getIncomeStatistic()
        } else {
          this.getIncomeStatistic(24)
        }
      },
      immediate: false
    }
  },
  created() {
    this.getIncomeStatistic(24)
  },
  methods: {
    getIncomeStatistic(months) {
      let self = this
      self.isDataLoading = true
      this.runApi(invoiceService.getIncomeStatistic(months), function (result) {
        self.totalIncome = result.data.reverse()
      }, undefined, function () {
        self.isDataLoading = false
      })
    },
  }
}
</script>
