<template>
  <vue-apex-charts
      type="line"
      height="400"
      :options="chartOptions"
      :series="series"
  />
</template>

<script>
import VueApexCharts from 'vue-apexcharts'

import {$themeColors} from "@themeConfig"
import inputHelper from "@/mixins/utils/inputHelper"

export default {
  name: 'StatisticTotalIncome',
  mixins: [inputHelper],
  components: {
    VueApexCharts
  },
  props: {
    data: {
      type: Array,
      default: () => [],
    }
  },
  computed: {
    series () {
      return [
        {
          data: this.data.map(item => item['total_income'])
        }
      ]
    },
    chartOptions() {
      let self = this
      return {
        chart: {
          zoom: {
            enabled: true,
          },
          toolbar: {
            show: true,
          },
        },
        markers: {
          strokeWidth: 7,
          strokeOpacity: 1,
          strokeColors: [$themeColors.light],
          colors: [$themeColors.warning],
        },
        colors: [$themeColors.warning],
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: 'straight',
        },
        grid: {
          xaxis: {
            lines: {
              show: true,
            },
          },
        },
        tooltip: {
          custom(data) {
            return `${'<div class="px-1 py-50"><span>'}${
                self.currencyFormat(data.series[data.seriesIndex][data.dataPointIndex])
            }</span></div>`
          },
        },
        xaxis: {
          categories: self.data.map(item => `${item['time']}`),
        },
        yaxis: {
          // opposite: isRtl,
        },
      }
    },
  }
}
</script>
