<template>
  <div>
    <p v-for="type in types" :key="type">
      <span v-if="type === 'text'">
        <span v-if="isLoading">
          <vue-skeleton-loader rounded class="w-100" height="25"/>
        </span>
        <span v-else>
          <slot></slot>
        </span>
      </span>
    </p>
  </div>
</template>

<script>
export default {
  name: "SkeletonLoader",
  props: {
    types: {
      type: Array,
      default: () => ['text']
    },
    isLoading: {
      type: Boolean,
      default: false,
      required: true
    }
  }
}
</script>
